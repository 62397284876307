<template>
  <div class="e-school-header mb-5">
    <nav class="navbar navbar-expand-md navbar-light fixed-top bg-white">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img
            :src="getImage('Global_College_of_PME.jpeg')"
            style="height: 63px"
          />
        </a>
        <!-- <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>-->

        <div class="d-inline-flex" id="navbarTogglerDemo02">
          <!-- <div class="form-inline mr-0 ml-auto my-2 my-lg-0"> -->
          <h6 class="login mx-3 font-weight-bold align-self-center">
            <a :href="getUrl()">
              Log in
              <img
                src="../assets/images/Icon-sign-in-alt-solid.jpg"
                width="20"
                height="20"
                class="img-fluid"
              />
            </a>
            <!-- <i class="fa fa-sign-in"></i> -->
          </h6>
          <button
            class="btn my-2 my-sm-0 apply-btn"
            data-toggle="modal"
            data-target="#applyNow"
            type="submit"
          >
            Apply now
          </button>
          <!-- </div> -->
        </div>
      </div>
    </nav>
    <registerSteps />
  </div>
</template>
<script>
import registerSteps from "./registerSteps";
export default {
  name: "headerAir",
  props: {
    // msg: String
  },
  components: {
    registerSteps,
  },
  methods: {
    getImage(img) {
      return require(`@/assets/images/${img}`);
    },
    getUrl() {
      const getEnvironment = () => {
        return window.location.host.split(".")[0] === "dev"
          ? "dev"
          : window.location.host.split(".")[0].indexOf("test") >= 0
          ? "test"
          : "production";
      };

      if (getEnvironment() == "dev") {
        return "https://dev.au.asu.edu/portal/";
      } else if (getEnvironment() == "test") {
        return "https://test.au.asu.edu/portal/";
      } else {
        return "https://au.asu.edu/portal/";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  position: fixed !important;
  top: -1px;
  left: 0px;
  height: 90px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-bottom: 5px solid #0f152e;
}

.apply-btn {
  border: 0.5px solid #000000;
  background-color: white;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  padding-left: 15px;
  padding-right: 15px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#navbarTogglerDemo02 {
  background: white;
  border-radius: 0 0 12px 12px;
}
.modal-dialog {
  width: 730px;
  max-width: 730px;
  top: 5%;
  position: relative !important;
}
@media screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 730px;
    position: relative !important;
  }
}
.modal-header {
  background: #ffffff;
  border-bottom: none;
}
.close {
  color: #191919 !important;
}
.font36 {
  font-size: 36px !important;
}
.btn-primary {
  border-radius: 18px;
  padding: 4px 18px;
  border: none;
  font-weight: 700;
  text-transform: capitalize;
  background: #00306d;
  color: white;
  font-size: 16px;
}
.modal-body span {
  line-height: 1.8;
}
</style>
