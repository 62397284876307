<template>
  <div id="applyNow" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header px-4 pb-2">
          <p class="font36 font-weight-bold mb-0">Get started</p>
          <button
            type="button"
            class="close font36 font-weight-bold"
            data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <span class="font14 text-left d-block px-4">
            If you meet the program eligibility requirements, you must create an
            Air University Portal account before applying. A common access card
            is required for the enrollment process.
            <br />
            <br />
            <b>STEP 1:</b> Go to
            <a href="https://www.airuniversity.af.edu/GCPME/" target="_blank"
              >The Global College of PME</a
            >
            homepage
            <br />
            <b>STEP 2:</b> Click the desired program name on the right-side
            navigation bar.
            <br />
            <b>STEP 3:</b> Scroll down the program page to the information tabs.
            <br />
            <b>STEP 4:</b> Non-OLMP applicants: Click the
            <strong>Eligibility and Enrollment</strong> tab. <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; OLMP applicants:
            Click the
            <strong>Application for Admission</strong> tab.
            <br />
            <b>STEP 5:</b> Click the link to download step-by-step enrollment
            instructions.
            <br />
            <b>STEP 6:</b> Follow the instructions to complete the enrollment
            process. <br />
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Note: A CAC card is
            required to complete the enrollment process.
            <br />
          </span>
          <div class="pt-5 pb-3">
            <a href="https://www.airuniversity.af.edu/GCPME/" target="_blank">
              <button class="btn btn-primary">
                Start Application at GCPME Homepage
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.modal-dialog {
  width: 730px;
  max-width: 730px;
  top: 5%;
  position: relative !important;
}
@media screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 730px;
    position: relative !important;
  }
}
.modal-header {
  background: #ffffff;
  border-bottom: none;
}
.close {
  color: #191919 !important;
}
.font36 {
  font-size: 36px !important;
}
.btn-primary {
  border-radius: 18px;
  padding: 4px 18px;
  border: none;
  font-weight: 700;
  text-transform: capitalize;
  background: #00306d;
  color: white;
  font-size: 16px;
}
.modal-body span {
  line-height: 1.8;
}
.home {
  color: #191919;
}
</style>
