<template>
  <div
    id="applyNow"
    class="container mt-5 d-flex justify-content-center col-11 col-md-7"
  >
    <div
      class="d-flex align-items-center justify-content-center w-100 flex-column apply"
    >
      <img
        style="z-index:-1;"
        class="w-100 position-absolute t-0 l-0 h-100"
        src="../assets/images/badge.png"
        alt=""
      />
      <p class="font16" style="color:white">
        Ready to take yourself to the next level?
      </p>
      <h1 class="heading-head font-weight-bold mb-4" style="color:white">
        APPLY NOW
      </h1>
      <div
        data-toggle="modal"
        data-target="#applyNow"
        class="button-wrapper mt-2"
      >
        <button
          data-toggle="modal"
          data-target="#applyNow"
          class="btn my-2 my-sm-0 apply-btn-1 mx-auto d-flex font16 get-started"
          type="submit"
        >
          Get started
        </button>
      </div>
    </div>
    <registerSteps />
  </div>
</template>

<script>
import registerSteps from "./registerSteps";
export default {
  name: "applynow",
  data() {
    return {};
  },
  components: {
    registerSteps
  }
};
</script>
<style>
.apply {
  background: linear-gradient(#181d36, #181d36);
  /* url("../assets/images/badge.png"); */
  /* // background: url("../images/Image 18.png"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 450px;
  width: 76% !important;
  border-left: 5px solid #ffc627;
  position: relative;
  z-index: 1;
  margin-bottom: -300px;
}
@media only screen and (max-width: 990px) {
  .apply {
    height: 240px;
    width: 90% !important;
    margin-bottom: -140px;
  }
}
.t-0 {
  top: 0;
}
.l-0 {
  left: 0;
}
.get-started:hover {
  color: white !important;
}
</style>
