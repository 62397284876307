<template>
  <div>
    <div class="container d-flex justify-content-center heading">
      <div class="vl"></div>
    </div>
    <!-- <div class="container mt-5 d-flex justify-content-center heading-text col-md-4 col-4"> -->
    <div
      class="container mt-5 d-flex justify-content-center heading-text col-lg-5"
    >
      <h2 class="heading-head text-uppercase font-weight-bold mb-0">
        THE RIGHT LEARNING AT THE RIGHT TIME
      </h2>
    </div>
    <div class="col-md-3 col-lg-2 col-6 d-flex m-auto px-5 py-5">
      <img
        :src="getImage('Global_College_of_PME.jpeg')"
        class="grey-section-logo w-100 h-100"
      />
    </div>
    <div class="container d-flex justify-content-center text-details">
      <p class="text-center w-70 h1size font16 mb-5">
        The Global College of Graduate Professional Military Education (PME) at
        Air University is charged with developing Air and Space Professionals
        who are critical and creative thinkers. Using an agile and tailored
        approach to lifelong learning, the Global College presents a full menu
        of educational options. Through the spectrum of PME, the Global College
        has the right learning at the right time to take you to
        <b>your next level.</b>
      </p>
    </div>
    <div
      class="
        container
        col-sm-12 col-md-12
        p-0
        d-flex
        align-items-end
        justify-content-center
      "
    >
      <div class="txt-box text-light px-3 py-4">
        <p class="font18 font-weight-bold">
          Learn from faculty who have been there
        </p>
        <div class="row justify-content-center">
          <div
            v-for="(data, i) in partner"
            :key="i"
            class="d-flex flex-column col-12 col-md-4"
          >
            <p class="font32 font-weight-bold mb-0" style="color: #ffc627">
              {{ data.num }}
            </p>
            <p class="font14">{{ data.des }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--  <div class="width65 container-fluid d-flex justify-content-center mt-5 mb-5">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe
          class="embed-responsive-item"
          src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
          allowfullscreen
        ></iframe>
      </div>
    </div>-->
  </div>
</template>
<script>
export default {
  name: "headingAndVideo",
  props: {
    // msg: String
  },
  data() {
    return {
      partner: [
        {
          num: "40%",
          des: "of faculty have a PhD.",
        },
        {
          num: "120+",
          des: "faculty members have command experience.",
        },
        {
          num: "12",
          des: "faculty members are former wing commanders.",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return require(`@/assets/images/${img}`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.heading {
  margin-top: 96px;
}

.vl {
  border-left: 3px solid gray;
  height: 62px;
}

.heading-text {
  margin-top: 20px;
}
.heading-head {
  // height: 49px;
}
.text-details {
  // padding-top: 32px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.w-70 {
  width: 70%;
}
@media (max-width: 768px) {
  .w-70 {
    width: 100%;
  }
}
.font32 {
  font-size: 32px;
}
</style>
