<template>
  <div>
    <div>
      <div class="explore pb-5" id="explore-section">
        <h2 class="heading-head text-center pt-5 pb-5 font-weight-bold">
          EXPLORE YOUR MILITARY EDUCATION OPTIONS
        </h2>
        <tabs :mode="mode" class="container">
          <tab title="OPME">
            <div class="container mx-auto pb-5">
              <h3
                class="
                  font24
                  pt-2
                  pb-2
                  font-weight-bold
                  justify-content-row-end
                "
              >
                Officer Professional Military Education
              </h3>
              <div style="display: flex; overflow-x: auto">
                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p class="mb-4 font24 font-weight-bold">
                      Squadron Officer School
                    </p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >The Global College’s Squadron Officer School distance
                      learning program is a primary developmental education
                      program. It also helps prepare students for leadership
                      within the squadron level and builds critical thinking
                      skills in the application of air and space power.</P
                    >

                    <button
                      class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                      data-toggle="modal"
                      data-target="#exampleModal3"
                      type="submit"
                    >
                      Learn more
                    </button>
                  </div>
                </div>

                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p class="mb-4 font24 font-weight-bold">
                      Online Master’s Program
                    </p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >The Online Master’s Program is an intermediate
                      developmental education opportunity designed to produce
                      highly effective officers for operational-level command or
                      staff positions. The degree program is offered in four
                      different concentrations: joint warfare, leadership,
                      operational warfare and nuclear weapons.</P
                    >

                    <img
                      src="../assets/images/JCSBadge.png"
                      class="
                        grey-section-logo
                        align-self-left
                        mb-1
                        h-48
                        d-none d-md-block
                      "
                      style="width: fit-content"
                    />

                    <p class="font9 d-none d-md-block mb-1">
                      JPME Phase 1 Credit Awarded<sup>*</sup>
                    </p>
                    <p class="font9 d-none d-md-block">
                      <sup>*</sup>Joint Warfare concentration only
                    </p>

                    <button
                      class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                      data-toggle="modal"
                      data-target="#exampleModal4"
                      type="submit"
                    >
                      Learn more
                    </button>
                  </div>
                </div>

                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p class="mb-4 font24 font-weight-bold">
                      Air Command and Staff College
                    </p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >The Global College’s Air Command and Staff College
                      distance learning intermediate developmental education
                      curriculum is designed to produce a more effective
                      field-grade officer serving in operational-level command
                      or staff positions.</P
                    >

                    <img
                      src="../assets/images/JCSBadge.png"
                      class="
                        grey-section-logo
                        align-self-left
                        mb-1
                        h-48
                        d-none d-md-block
                      "
                      style="width: fit-content"
                    />

                    <p class="font9 d-none d-md-block mb-1">
                      JPME Phase 1 Credit Awarded<sup></sup>
                    </p>
                    <p class="font9 d-none d-md-block">
                      <!-- <sup>*</sup>Joint Warfare concentration -->
                    </p>

                    <div class="button-wrapper">
                      <button
                        class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                        data-toggle="modal"
                        data-target="#exampleModal6"
                        type="submit"
                      >
                        Learn more
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p class="mb-4 font24 font-weight-bold">Air War College</p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >The Global College’s Air War College distance learning
                      program is a senior developmental education program. It
                      provides a curriculum that prepares graduates for
                      strategic leadership in support of national security
                      objectives.</P
                    >

                    <button
                      class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                      data-toggle="modal"
                      data-target="#exampleModal5"
                      type="submit"
                    >
                      Learn more
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal 1 -->

            <div
              class="modal fade"
              id="exampleModal3"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel3"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-slideout modal-md"
                role="document"
              >
                <div class="modal-content">
                  <div class="b-modal-header">
                    <nav class="navbar navbar-light bg-grey modal-nav top-fix">
                      <span
                        class="close font18 d-inline-flex"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img
                          src="../assets/images/chevron-left-solid.svg"
                          class="close-icon"
                          alt
                        />
                        Back
                      </span>
                    </nav>
                  </div>

                  <div class="modal-body text-left">
                    <img
                      src="../assets/images/sos.jpg"
                      class="w-100 h-auto mt-4"
                      alt="An Air Force engineer looks at a an electric panel with several cords and lights."
                    />

                    <div class="p-4 bb-5">
                      <h3 class="modal-heading">
                        Squadron Officer School distance learning
                      </h3>

                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />15 courses
                        <br />
                        <span style="margin-left: 24px"
                          >○ 9 self-paced + 3 facilitated core courses</span
                        >
                        <br />
                        <span style="margin-left: 24px"
                          >○ 2 self-paced + 1 facilitated elective courses</span
                        >
                      </p>

                      <!-- <p class="modal-p">
                <img
                  class="pr-2"
                  src="../assets/images/check-circle-solid.svg"
                />185 hours
              </p>-->

                      <hr />

                      <h5 class="font-weight-bold">Requirements</h5>

                      <p>
                        The eligibility criteria for Squadron Officer School
                        distance learning are found in Air Force Instruction
                        (AFI) 36-2656, Developmental Education.
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <b>Air Force and Space Force Active Duty</b> - The
                          intent is for all active duty to attend Squadron
                          Officer School in-residence and the Squadron Officer
                          School distance learning program to serve as an
                          alternative if an officer is unable to attend the
                          in-residence program. Therefore the following
                          restrictions apply for active duty officers:
                          <br />
                          <br />○ Line-of-the-Air Force (LAF) captains are
                          prohibited from enrolling in the Squadron Officer
                          School distance learning course until seven years
                          TAFCS.
                          <br />
                          <br />○ Exceptions: LAF-J, HC, NC, MSC, BSC, MC and DC
                          officers may enroll in Squadron Officer School
                          distance learning on or after their date of rank to
                          captain.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>Air National Guard and Air Force Reserve</b> - Upon
                          selection to captain, all Air National Guard and Air
                          Force Reserve officers are eligible to enroll in
                          Squadron Officer School distance learning.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>Civilians - DoD civilian employees</b> in the
                          grades of GS-9 to GS-12 and above (or
                          <a
                            href="https://www.airuniversity.af.edu/Portals/10/eSchool/documents/PME_Performance_Plan_Equivalencies_12_May_20.pdf?ver=2020-08-07-160016-697"
                            >equivalent</a
                          >) who possess regionally-accredited baccalaureate
                          degrees may enroll in Squadron Officer School distance
                          learning.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>Sister services</b> - Sister Service Officers in
                          the grade of O-3 may enroll. However, as per army
                          regulations, Army Officers will not enroll in other
                          than Army schools without written approval of the DCS,
                          G-3/5/7 (Director of Training). AR 350-1, 10 DEC 2017.
                        </li>
                      </ul>

                      <p>
                        <b>Note:</b> Officers who complete the resident Squadron
                        Officer School program are prohibited from enrolling or
                        reenrolling in the Squadron Officer School distance
                        learning course.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal 2 -->

            <div
              class="modal fade"
              id="exampleModal4"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel4"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-slideout modal-md"
                role="document"
              >
                <div class="modal-content">
                  <div class="b-modal-header">
                    <nav class="navbar navbar-light bg-grey modal-nav top-fix">
                      <span
                        class="close font18 d-inline-flex"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img
                          src="../assets/images/chevron-left-solid.svg"
                          class="close-icon"
                          alt
                        />
                        Back
                      </span>
                    </nav>
                  </div>

                  <div class="modal-body text-left">
                    <img
                      src="../assets/images/olmp.jpg"
                      class="w-100 h-auto mt-4"
                    />

                    <div class="p-4 bb-5">
                      <h3 class="modal-heading">Online Master’s Program</h3>

                      <!-- <h5 class="description">Description</h5> -->

                      <p class="modal-p">
                        Upon successful completion of any of the Online Master’s
                        Program concentrations, students earn an accredited
                        Master of Military Operational Art and Science degree.
                        Students who complete the joint warfare concentration
                        also earn Joint Professional Military Education – Phase
                        1 credit. If a student completes any of the other 3 OLMP
                        concentrations, they will have to complete ACSC DL 7.0
                        "Supplemental" to earn JPME Phase 1 credit.
                        <br />
                        <br />Due to the level of work required, new students
                        can take only one course each term; however, they may be
                        approved to take two courses per term after they have
                        successfully completed their first three courses.
                        Tuition is free and there is no active-duty service
                        commitment associated with the program; however,
                        students incur the cost of required books, personal
                        computer and Internet connection. Additionally, students
                        will require access to a common access card-enabled
                        system for initial program enrollment. Students who work
                        steadily should complete their master’s degree in two
                        years; however, students have up to five years to meet
                        all degree requirements.
                      </p>

                      <br />

                      <hr />

                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />10 eight-week courses
                        <br />
                        <span style="margin-left: 24px"
                          >○ 6 facilitated core courses</span
                        >
                        <br />
                        <span style="margin-left: 24px"
                          >○ 4 facilitated concentration courses</span
                        >
                      </p>

                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />30 semester hours of credit
                      </p>

                      <hr />

                      <h5 class="font-weight-bold">Requirements</h5>

                      <p class="modal-p">
                        The eligibility criteria are found in Air Force
                        Instruction 36-2656, Developmental Education. Additional
                        criteria by concentration is as follows:
                      </p>

                      <p class="modal-p">
                        <b>Joint warfare concentration</b>
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <span class="font-weight-bold">U.S. Air Force:</span>
                          Eligible personnel include O-4 selects and O-4s on
                          active duty or in the Air Force Reserve or Air
                          National Guard. Individuals who have completed
                          <b>either</b> a master's degree or intermediate
                          developmental education are still eligible. However,
                          individuals who have already completed <b>both</b> a
                          master’s degree <b>and</b> intermediate developmental
                          education are ineligible.
                          <br />
                          <br />
                        </li>

                        <li>
                          <span class="font-weight-bold">Civilians:</span> Air
                          Force civilians in grades GS-12 and GS-13 who have not
                          already completed a master's degree may be
                          competitively selected to enroll in the program. Air
                          Force civilians should contact their local civilian
                          personnel office for more information.
                          <br />
                          <br />
                        </li>

                        <li>
                          <span class="font-weight-bold"
                            >U.S. sister service (military):</span
                          >
                          Eligible personnel include O-4 selects and O-4s on
                          Active Duty or in the Guard or Reserves. Individuals
                          who have completed
                          <b>either</b> a master's degree or intermediate
                          developmental education are still eligible. However,
                          individuals who have already completed <b>both</b> a
                          master’s degree <b>and</b> intermediate developmental
                          education are ineligible. <b>Note:</b> Sister service
                          acceptance into the program is limited by space
                          availability within the program. Additionally, as per
                          Army regulations, Army officers may not enroll in
                          schools other than Army schools without written
                          approval of the DCS, G-3/5/7 (director of training).
                          AR 350-1, Dec. 10, 2017.
                          <br />
                          <br />
                        </li>
                      </ul>
                      <p class="modal-p">
                        <b>Leadership Concentration</b>
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <b>U.S. Air Force:</b> Eligible personnel include only
                          U.S. Air Force active duty O-3s who:
                          <br />
                          <br />○ Have six or more years of total active federal
                          commissioned service.
                          <br />
                          <br />○ Have completed Squadron Officer School
                          (resident or online).
                          <br />
                          <br />○ Do not have a master’s degree.
                          <br />
                        </li>

                        <li>
                          There are limited opportunities for Guard and Reserve
                          O-3s who meet all other requirements to participate in
                          the leadership concentration. Contact the Guard or
                          Reserve headquarters for more information.
                        </li>
                      </ul>

                      <p class="modal-p">
                        <b>Operational Warfare Concentration</b>
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <b>U.S. Air Force:</b> Eligible personnel include only
                          U.S. Air Force O-3s through O-5s who:
                          <br />
                          <br />○ Have six or more years of total active federal
                          commissioned service.
                          <br />
                          <br />○ Have completed Squadron Officer School
                          (resident or online).
                          <br />
                          <br />○ Do not have a master’s degree
                          <br />
                          <br />○ Graduated from the
                          <b>Air Force Weapons Instructor Course</b> after
                          January 2009.
                          <br />
                          <br />
                        </li>
                        <li>
                          The operational warfare concentration is specifically
                          designed to award 12 credit hours in operational
                          warfare studies toward the Online Master’s Program to
                          Weapons Instructor Course graduates once they have
                          completed the Online Master’s Program online
                          application, are admitted to the Online Master’s
                          Program and have completed the seven core Online
                          Master’s Program courses.
                        </li>
                      </ul>

                      <p class="modal-p">
                        <b>Nuclear Weapons Concentration</b>
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <b>U.S. Air Force:</b> Eligible personnel include only
                          USAF O-3s through O-5s who:
                          <br />
                          <br />○ Have six or more years of total active federal
                          commissioned service.
                          <br />
                          <br />○ Have completed Squadron Officer School
                          (resident or online).
                          <br />
                          <br />○ Do not have a master’s degree.
                          <br />
                          <br />○ Have completed the
                          <b
                            >Nuclear Weapons Effects, Policy and Proliferation
                            Certificate Program</b
                          >
                          administered by Air Force Institute of Technology.
                          <br />
                          <br />
                        </li>
                        <li>
                          The nuclear weapons concentration is specifically
                          designed to award 12 credit hours in nuclear weapons
                          studies toward the Online Master’s Program to Nuclear
                          Weapons Effects, Policy and Proliferation Certificate
                          Program graduates once they have completed the Online
                          Master’s Program online application, are admitted to
                          the Online Master’s Program and have completed the
                          seven core Online Master’s Program courses.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal 3  -->

            <div
              class="modal fade"
              id="exampleModal6"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel6"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-slideout modal-md"
                role="document"
              >
                <div class="modal-content">
                  <div class="b-modal-header">
                    <nav class="navbar navbar-light bg-grey modal-nav top-fix">
                      <span
                        class="close font18 d-inline-flex"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img
                          src="../assets/images/chevron-left-solid.svg"
                          class="close-icon"
                          alt
                        />
                        Back
                      </span>
                    </nav>
                  </div>

                  <div class="modal-body text-left">
                    <img
                      src="../assets/images/acsc.jpg"
                      class="w-100 h-auto mt-4"
                    />
                    <div class="p-4 bb-5">
                      <h3 class="modal-heading">
                        Air Command and Staff College distance learning
                      </h3>

                      <hr />

                      <br />
                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />17-18 courses
                        <br />
                        <span style="margin-left: 24px"
                          >○ 15 foundational courses (11 self-paced courses + 4
                          facilitated courses)</span
                        >
                        <br />
                        <span style="margin-left: 24px"
                          >○ 2-3 elective-focused courses, totaling 45
                          hours</span
                        >
                      </p>
                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />260 hours
                      </p>
                      <hr />

                      <h5 class="font-weight-bold">Requirements</h5>

                      <p class="modal-p">
                        The eligibility criteria are found in Air Force
                        Instruction 36-2656, Developmental Education.
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <b>Military</b> – The program is offered to O-4
                          selects and above. Persons may be active duty Air
                          Force, Air Force Reserves or Air National Guard;
                          sister service active duty, Guard or Reserve; or Civil
                          Air Patrol.
                          <br />
                          <br />○ Air National Guard and Air Force Reserve
                          members have the opportunity to enhance their
                          intermediate developmental education experience during
                          <a
                            href="https://www.airuniversity.af.edu/eSchool/ARCS/"
                            >Air Reserve Component Seminars</a
                          >
                          <br />
                          <br />○ Army officers: Officers will not enroll in
                          schools other than Army schools without written
                          approval of the DCS, G-3/5/7 (director of training).
                          AR 350-1, Dec. 10, 2017.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>Department of Defense civilians:</b> Department of
                          Defense civilian employees in the grade of GS-11 thru
                          GS-13 or
                          <a
                            href="https://www.airuniversity.af.edu/Portals/10/eSchool/documents/PME_Performance_Plan_Equivalencies_12_May_20.pdf?ver=2020-08-07-160016-697"
                            >equivalents</a
                          >
                          with a bachelor’s degree may also enroll. NH-II
                          Civilians need to contact Air Force HQ/A1 for a
                          determination of eligibility.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>International:</b> Requests for Professional
                          Military Education correspondence courses for
                          international students are forwarded to the Air Force
                          Security Assistance Training Squadron through the
                          country Security Cooperation Organization or country
                          embassy. The request must:
                          <br />
                          <br />○ Identify an existing source of funding (for
                          example, Foreign Military Sales training case) or
                          request preparation of a new training case
                          <br />
                          <br />○ Provide the name, address, email and phone
                          number for the U.S. military officer or U.S. civilian
                          employee who will sponsor the student
                          <br />
                          <br />○ Include written verification by the U.S.
                          sponsor that the student meets the rank prerequisite
                          for the desired program and is proficient in the
                          English language to complete the program successfully.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- modal 4-->

            <div
              class="modal fade"
              id="exampleModal5"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel5"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-slideout modal-md"
                role="document"
              >
                <div class="modal-content">
                  <div class="b-modal-header">
                    <nav class="navbar navbar-light bg-grey modal-nav top-fix">
                      <span
                        class="close font18 d-inline-flex"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img
                          src="../assets/images/chevron-left-solid.svg"
                          class="close-icon"
                          alt
                        />
                        Back
                      </span>
                    </nav>
                  </div>

                  <div class="modal-body text-left">
                    <img
                      src="../assets/images/awc.jpg"
                      class="w-100 h-auto mt-4"
                    />

                    <div class="p-4 bb-5">
                      <h3 class="modal-heading">
                        Air War College distance learning
                      </h3>

                      <hr />

                      <br />
                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />9 courses
                        <br />
                        <span style="margin-left: 24px"
                          >○ 6 self-paced courses.</span
                        >
                        <br />
                        <span style="margin-left: 24px"
                          >○ 3 facilitated courses.</span
                        >
                      </p>
                      <p class="modal-p">
                        <img
                          class="pr-2"
                          src="../assets/images/check-circle-solid.svg"
                        />200 hours
                      </p>
                      <hr />

                      <h5 class="font-weight-bold">Requirements</h5>

                      <p class="modal-p">
                        The eligibility criteria are found in Air Force
                        Instruction 36-2656, Developmental Education.
                      </p>

                      <ul style="margin-left: -22px" class="mdal-list">
                        <li>
                          <b>Military:</b> The program is offered to O-5 selects
                          and above. Persons may be active duty Air Force, Air
                          Force Reserve or Air National Guard; sister service
                          active duty, Guard or Reserve; or Civil Air Patrol.
                          <br />
                          <br />○ Air National Guard and Air Force Reserve
                          members have the opportunity to enhance their Air War
                          College distance learning/senior developmental
                          education program experience with
                          <a
                            href="https://www.airuniversity.af.edu/eSchool/ARCS/"
                            >Air Reserve Component Seminars.</a
                          >
                          <br />
                          <br />○ Army officers: Officers will not enroll in
                          schools other than Army schools without written
                          approval of the DCS, G-3/5/7 (director of training).
                          AR 350-1, Dec. 10, 2017.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>Civilian: DoD civilian employees</b> in the grades
                          of GS 13/14/15 or
                          <a
                            href="https://www.airuniversity.af.edu/Portals/10/eSchool/documents/PME_Performance_Plan_Equivalencies_12_May_20.pdf?ver=2020-08-07-160016-697"
                            >equivalent</a
                          >
                          are eligible to enroll.
                          <br />
                          <br />
                        </li>

                        <li>
                          <b>International:</b> Requests for Professional
                          Military Education correspondence courses for
                          international students are forwarded to the Air Force
                          Security Assistance Training Squadron through the
                          country Security Cooperation Organization or country
                          embassy. The request must:
                          <br />
                          <br />○ Identify an existing source of funding (for
                          example, Foreign Military Sales training case) or
                          request preparation of a new training case
                          <br />
                          <br />○ Provide the name, address, email and phone
                          number for the U.S. military officer or U.S. civilian
                          employee who will sponsor the student
                          <br />
                          <br />○ Include written verification by the U.S.
                          sponsor that the student meets the rank prerequisite
                          for the desired program and is proficient in the
                          English language to complete the program successfully.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <tab title="EPME">
            <div class="container mx-auto pb-5">
              <h3
                class="
                  font24
                  pt-2
                  pb-2
                  font-weight-bold
                  justify-content-row-end
                "
              >
                Enlisted Professional Military Education
              </h3>
              <div style="display: flex; overflow-x: auto">
                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p class="mb-4 font24 font-weight-bold">
                      Airman Leadership School
                    </p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >Airman Leadership School (ALS) is an education program
                      held at base level to prepare Senior Airmen for positions
                      of greater responsibility. The course teaches leadership
                      skills required of supervisors and reporting officials
                      throughout the Air and Space Forces. ALS enhances the
                      development of Senior Airmen by strengthening their
                      ability to lead, follow, and manage while they gain a
                      broader understanding of the military profession. ALS is
                      attended by Senior Airmen (E-4) and required for promotion
                      to Staff Sergeant (E-5).</P
                    >

                    <button
                      class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                      data-toggle="modal"
                      data-target="#exampleModal7"
                      type="submit"
                    >
                      Learn more
                    </button>
                  </div>
                </div>

                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p
                      class="mb-4 font24 font-weight-bold"
                      style="word-break: break-word"
                    >
                      Noncommissioned Officer Academy (NCOA)
                    </p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >The Noncommissioned Officer Academy (NCOA) is the second
                      level of enlisted PME and prepares Technical Sergeants to
                      be professional, war-fighting Airmen and Space
                      Professionals who can manage and lead units in the
                      employment of Air and Space power.</P
                    >

                    <button
                      class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                      data-toggle="modal"
                      data-target="#exampleModal8"
                      type="submit"
                    >
                      Learn more
                    </button>
                  </div>
                </div>

                <div
                  class="
                    flex-wrapper
                    explore-each
                    col-xl-3 col-lg-3 col-md-5 col-sm-10 col-10
                    text-left
                    my-2
                    pl-0
                  "
                >
                  <div
                    class="
                      flex-col-wrapper
                      pt-5
                      pb-6
                      px-3
                      col-border
                      w-100
                      justify-content-start
                    "
                  >
                    <p
                      class="mb-4 font24 font-weight-bold"
                      style="word-break: break-word"
                    >
                      Senior Noncommissioned Officer Academy (SNCOA)
                    </p>

                    <P class="mini-font mb-5 d-none d-md-block"
                      >The Air Force Senior Noncommissioned Officer Academy
                      (SNCOA) is the third level of enlisted PME. SNCOA prepares
                      senior NCOs to lead the enlisted force in the employment
                      of AirPower in support of US national security
                      objectives.</P
                    >

                    <button
                      class="btn my-2 my-sm-0 apply-btn-1 d-flex learn-more"
                      data-toggle="modal"
                      data-target="#exampleModal9"
                      type="submit"
                    >
                      Learn more
                    </button>
                  </div>
                </div>
              </div>

              <!-- Modal 5 -->

              <div
                class="modal fade"
                id="exampleModal7"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel3"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="b-modal-header">
                      <nav
                        class="navbar navbar-light bg-grey modal-nav top-fix"
                      >
                        <span
                          class="close font18 d-inline-flex"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img
                            src="../assets/images/chevron-left-solid.svg"
                            class="close-icon"
                            alt
                          />
                          Back
                        </span>
                      </nav>
                    </div>

                    <div class="modal-body text-left">
                      <img
                        src="../assets/images/als.jpeg"
                        class="w-100 h-auto mt-4"
                        alt="An Air Force engineer looks at a an electric panel with several cords and lights."
                      />

                      <div class="p-4 bb-5">
                        <h3 class="modal-heading">
                          Airman Leadership School Distance Learning Program
                          (ALS DLP)
                        </h3>
                        <p class="modal-p">
                          <b
                            >Airman Leadership School Distance Learning
                            Program</b
                          >
                          is the foundational level of enlisted leadership
                          development. The program focuses on leadership skills
                          required of supervisors and reporting officials
                          throughout the Air Force. It is designed to enhance
                          the development of Senior Airmen by strengthening
                          their knowledge about leadership, followership, and
                          management while broadening their understanding of the
                          missions and cultures of the Air Force. It does so
                          with a focus on Air Force missions, leading people,
                          strategies for problem solving, and cultural
                          foundations.
                        </p>
                        <h5 class="font-weight-bold">Learn More</h5>
                        <p class="modal-p">
                          <!-- <img
                            class="pr-2"
                            src="../assets/images/check-circle-solid.svg"
                          /> -->
                          4 courses
                          <br />
                          <span style="margin-left: 24px">3 self-paced</span>
                          <br />
                          <span style="margin-left: 24px"
                            >1 capstone course</span
                          >
                        </p>

                        <!-- <hr /> -->

                        <h5 class="font-weight-bold">Requirements</h5>

                        <p>
                          The eligibility requirements for ALS DLP are found in
                          Air Force Instruction 36-2670 Total Force Development.
                        </p>
                        <p>
                          The program is offered to Senior Airman and Staff
                          Sergeant Selects from the Air Force Reserve and Air
                          National Guard.
                        </p>
                        <h5 class="font-weight-bold">Why ALS DLP</h5>

                        <p>
                          The ALS DLP prepares Airmen to be adaptable for
                          current and future leadership and management
                          challenges and meets the PME requirements for
                          promotion to the rank of Staff Sergeant.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Modal 6 -->

              <div
                class="modal fade"
                id="exampleModal8"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel4"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="b-modal-header">
                      <nav
                        class="navbar navbar-light bg-grey modal-nav top-fix"
                      >
                        <span
                          class="close font18 d-inline-flex"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img
                            src="../assets/images/chevron-left-solid.svg"
                            class="close-icon"
                            alt
                          />
                          Back
                        </span>
                      </nav>
                    </div>

                    <div class="modal-body text-left">
                      <img
                        src="../assets/images/nco.jpeg"
                        class="w-100 h-auto mt-4"
                      />

                      <div class="p-4 bb-5">
                        <h3 class="modal-heading">
                          Non-Commissioned Officer Academy Distance Learning
                          Program (NCOA DLP)
                        </h3>

                        <!-- <h5 class="description">Description</h5> -->

                        <p class="modal-p">
                          <b
                            >The Non-Commissioned Officer Academy Distance
                            Learning Program</b
                          >
                          is the second level of enlisted PME and provides
                          professional military education to prepare NCOs to
                          continue strengthening the Air Force culture, lead and
                          manage teams, solve problems collaboratively, and
                          connect national strategic documents to joint
                          operations. The NCOA DL program enhances the
                          development of NCOs by reinforcing their knowledge
                          about leadership and management while increasing their
                          level of understanding of joint missions and cultures.
                        </p>
                        <h5 class="font-weight-bold">Learn More</h5>
                        <p class="modal-p">
                          <!-- <img
                            class="pr-2"
                            src="../assets/images/check-circle-solid.svg"
                          /> -->
                          5 courses
                          <br />
                          <span style="margin-left: 24px">4 self-paced</span>
                          <br />
                          <span style="margin-left: 24px"
                            >1 capstone course</span
                          >
                        </p>

                        <!-- <hr /> -->

                        <h5 class="font-weight-bold">Requirements</h5>

                        <p class="modal-p">
                          The eligibility requirements for NCOA DLP are found in
                          Air Force Instruction 36-2670 Total Force Development.
                        </p>

                        <p class="modal-p">
                          The program is offered to Technical Sergeants and
                          Technical Sergeant Selects from the Air Force Reserve
                          and Air National Guard.
                        </p>
                        <br />
                        <h5 class="font-weight-bold">Why NCOA DLP</h5>
                        <p class="modal-p">
                          The NCOA DLP is a primary-level force development
                          opportunity that prepares NCOs to be professional
                          war-fighting Airmen that can manage and lead units in
                          the employment of Air power. Completion is required
                          prior to promotion to MSgt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Modal 7 -->
              <div
                class="modal fade"
                id="exampleModal9"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel3"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-slideout modal-md"
                  role="document"
                >
                  <div class="modal-content">
                    <div class="b-modal-header">
                      <nav
                        class="navbar navbar-light bg-grey modal-nav top-fix"
                      >
                        <span
                          class="close font18 d-inline-flex"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img
                            src="../assets/images/chevron-left-solid.svg"
                            class="close-icon"
                            alt
                          />
                          Back
                        </span>
                      </nav>
                    </div>

                    <div class="modal-body text-left">
                      <img
                        src="../assets/images/snco.jpeg"
                        class="w-100 h-auto mt-4"
                        alt="An Air Force engineer looks at a an electric panel with several cords and lights."
                      />

                      <div class="p-4 bb-5">
                        <h3 class="modal-heading">
                          Senior Non-Commissioned Officer Academy Distance
                          Learning Program (SNCOA DLP)
                        </h3>
                        <p class="modal-p">
                          <b
                            >The Senior Non-Commissioned Officer Academy
                            Distance Learning Program</b
                          >
                          is the third level of enlisted PME. The program
                          prepares senior non-commissioned officers to lead the
                          enlisted force in the employment of airpower in
                          support of US national security objectives. It
                          educates SNCOs to become adaptable senior enlisted
                          leaders able to operate critically, strategically and
                          jointly in complex and ambiguous environments.
                          Additionally, it provides education to develop skills
                          to lead in joint operations and strengthen
                          organizational culture, while improving unit
                          effectiveness.
                        </p>

                        <h5 class="font-weight-bold">Learn More</h5>
                        <p class="modal-p">
                          <!-- <img
                            class="pr-2"
                            src="../assets/images/check-circle-solid.svg"
                          /> -->
                          5 courses
                          <br />
                          <span style="margin-left: 24px">4 self-paced</span>
                          <br />
                          <span style="margin-left: 24px"
                            >1 capstone course</span
                          >
                        </p>

                        <!-- <p class="modal-p">
                <img
                  class="pr-2"
                  src="../assets/images/check-circle-solid.svg"
                />185 hours
              </p>-->

                        <hr />

                        <h5 class="font-weight-bold">Requirements</h5>

                        <p class="modal-p">
                          The eligibility requirements for SNCOA DLP are found
                          in Air Force Instruction 36-2670 Total Force
                          Development.
                        </p>

                        <p class="modal-p">
                          The program is offered to Master Sergeants and Senior
                          Master Sergeants from the Air Force Reserve and Air
                          National Guard.
                        </p>
                        <br />
                        <h5 class="font-weight-bold">Why SNCOA DLP</h5>
                        <p class="modal-p">
                          The SNCOA DLP is an intermediate and senior-level
                          force development opportunity that prepares SNCOs to
                          lead and operate in joint and complex environments.
                          Completion is required for promotion to SMSgt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <div
            id="my-content"
            class="d-md-none d-block px-4 mx-2"
            style="text-align: left; font-size: 18px"
          >
            Scroll for more
            <img
              src="../assets/images/arrow-right-solid.svg"
              height="15"
              class=""
            />
          </div>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@/components/tab.vue";
import Tabs from "@/components/tabs.vue";
export default {
  name: "Explore",
  components: {
    Tab,
    Tabs,
  },
  data() {
    return {
      mode: "light",
    };
  },
};
</script>

<style>
.navbar {
  position: absolute !important;
}

.close {
  opacity: 1 !important;
}

.modal-body {
  padding: 0px !important;
}

.modal-heading {
  font-size: 36px;
}

@media (max-width: 768px) {
  .modal-heading {
    font-size: 24px;
  }
}

.description {
  font-size: 18px;
}

@media screen and (max-width: 800px) {
  .flex-wrapper {
    min-width: 40%;
    max-width: 100vw;
  }
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  #my-content {
    margin-top: -60px;
  }
}

.modal-p,
.mdal-list li {
  font-size: 16px !important;
  color: #191919 !important;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.font9 {
  font-size: 9px;
}

.bb-5 {
  border-bottom: 10px solid #00306d;
}

.learn-more {
  position: absolute;
  bottom: 5%;
}

.button-wrapper {
  display: contents;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.h-48 {
  height: 48px;
}

.top-fix {
  position: fixed;
  top: 0px;
  z-index: 4;
  width: 100%;
}

.close-icon {
  /* -webkit-filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
  filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white); */
  padding-right: 5px;
  /* height: 15px; */
  /* transform: rotate(90deg); */
}
.learn-more:hover {
  color: white !important;
}
</style>
