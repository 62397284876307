<template>
  <div id="footer" class="footer-section">
    <div class="grey-section container">
      <div class="pt-5 pb-5 mb-5 text-align-center align-items-center">
        <div class="col-12">
          <img
            src="../assets/images/Group8088.png"
            class="grey-section-image"
          />
        </div>
        <div
          class="
            col-xl-6 col-lg-6 col-md-6 col-sm-12
            d-inline-flex
            pt-5
            px-0 px-md-1
          "
        >
          <p class="grey-section-p my-auto font14">
            The Global College, powered by ASU, is an educational partnership
            between Air University and Arizona State University focused on
            enhancing student learning in online professional military
            education.
          </p>
        </div>
      </div>
    </div>
    <primaryFooter />
    <div
      id="innovation-bar"
      class="row little-foot hidden-print"
      role="contentinfo"
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <div
              class="
                d-flex
                flex-column flex-lg-row
                align-items-center
                justify-content-center
              "
            >
              <img
                src="https://live-asuocms.ws.asu.edu/sites/default/files/innovation-logo.png"
                alt="Number one in the U.S. for innovation. #1 ASU, #2 Stanford, #3 MIT. - U.S. News and World Report, 5 years, 2016-2020"
                class="order-1 order-lg-2 innovation-image"
              />
              <nav
                aria-label="University Services"
                id="my-footer-content-desktop"
                class="
                  nav
                  grid-flex-menu
                  order-2 order-lg-1
                  mb-space-sm mb-lg-0
                "
                style="text-align: center"
              >
                <a
                  href="https://www.asu.edu/map/interactive/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="
                    nav-link
                    text-dark
                    menu-item
                    font-weight-bold
                    py-space-xxs py-lg-0
                  "
                  data-v-1bb7eb72=""
                >
                  Maps and Locations </a
                ><a
                  href="https://www.asu.edu/asujobs"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="
                    nav-link
                    text-dark
                    menu-item
                    font-weight-bold
                    py-space-xxs py-lg-0
                  "
                >
                  Jobs </a
                ><a
                  href="https://isearch.asu.edu/asu-people/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="
                    nav-link
                    text-dark
                    menu-item
                    font-weight-bold
                    py-space-xxs py-lg-0
                  "
                >
                  Directory </a
                ><a
                  href="https://www.asu.edu/contactasu/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="
                    nav-link
                    text-dark
                    menu-item
                    font-weight-bold
                    py-space-xxs py-lg-0
                  "
                >
                  Contact ASU </a
                ><a
                  href="https://my.asu.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="
                    nav-link
                    text-dark
                    menu-item
                    font-weight-bold
                    py-space-xxs py-lg-0
                  "
                >
                  My ASU
                </a>
              </nav>
              <nav
                aria-label="University Services"
                id="my-footer-content-mobile"
                class="row order-2 col-xs-12 col-sm-12"
                style="width: 100vw"
              >
                <div class="row col-sm-12 col-xs-12 col-12">
                  <a
                    href="https://www.asu.edu/map/interactive/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="nav-link font-weight-bold col-sm-6 col-xs-6 col-6"
                    style="text-align: left"
                  >
                    Maps and Locations </a
                  ><a
                    href="https://www.asu.edu/asujobs"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="nav-link font-weight-bold col-sm-6 col-xs-6 col-6"
                    style="text-align: right"
                  >
                    Jobs
                  </a>
                </div>
                <div class="row col-sm-12 col-xs-12">
                  <a
                    href="https://isearch.asu.edu/asu-people/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="nav-link font-weight-bold col-sm-6 col-xs-6 col-6"
                    style="text-align: left"
                  >
                    Directory </a
                  ><a
                    href="https://www.asu.edu/contactasu/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="nav-link font-weight-bold col-sm-6 col-xs-6 col-6"
                    style="text-align: right"
                  >
                    Contact ASU
                  </a>
                </div>
                <div class="row col-sm-12 col-xs-12">
                  <a
                    href="https://my.asu.edu/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="nav-link font-weight-bold col-sm-6 col-xs-6 col-6"
                    style="text-align: left"
                  >
                    My ASU
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="little-foot" role="contentinfo" id="my-footer-content-mobile">
      <div class="row region region-asu-footer">
        <div>
          <div class="row col-sm-12 col-xs-12 col-12">
            <div
              href="https://www.asu.edu/copyright/"
              target="_blank"
              class="link-class col-xs-6 col-sm-6 col-6"
              style="text-align: left"
            >
              Copyright and Trademark
            </div>
            <div
              href="https://www.asu.edu/tou/"
              target="_blank"
              class="link-class col-xs-6 col-sm-6 col-6"
              style="text-align: right"
            >
              Terms of Use
            </div>
          </div>
          <div class="row col-sm-12 col-xs-12 col-12">
            <div
              href="https://www.asu.edu/accessibility/"
              target="_blank"
              class="link-class col-xs-6 col-sm-6 col-6"
              style="text-align: left"
            >
              Accessibility
            </div>
            <div
              href="https://www.asu.edu/emergency/"
              target="_blank"
              class="link-class col-xs-6 col-sm-6 col-6"
              style="text-align: right"
            >
              Emergency
            </div>
          </div>
          <div class="row col-sm-12 col-xs-12 col-12">
            <div
              href="https://www.asu.edu/privacy/"
              target="_blank"
              class="link-class col-xs-6 col-sm-6 col-6"
              style="text-align: left"
            >
              Privacy
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="little-foot" role="contentinfo" id="my-footer-content-desktop">
      <div class="row region region-asu-footer">
        <ul>
          <li>
            <a
              href="https://www.asu.edu/copyright/"
              target="_blank"
              class="link-class"
              >Copyright and Trademark</a
            >
          </li>
          <li>
            <a
              href="https://www.asu.edu/accessibility/"
              target="_blank"
              class="link-class"
              >Accessibility</a
            >
          </li>
          <li>
            <a
              href="https://www.asu.edu/privacy/"
              target="_blank"
              class="link-class"
              >Privacy</a
            >
          </li>
          <li>
            <a
              href="https://www.asu.edu/tou/"
              target="_blank"
              class="link-class"
              >Terms of Use</a
            >
          </li>
          <li>
            <a
              href="https://www.asu.edu/emergency/"
              target="_blank"
              class="link-class"
              >Emergency</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import primaryFooter from "./primaryFooter";
export default {
  name: "Footer",
  components: {
    primaryFooter,
  },
};
</script>
<style>
.grey-section-image {
  width: 50%;
}
.little-foot ul {
  width: 100vw !important;
  text-align: center;
}
.little-foot {
  width: 100vw;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .grey-section-image {
    width: 100%;
  }
}
.grey-section-p {
  margin-top: 5% !important;
}
.text-align-center {
  text-align: -webkit-center;
}
.margin5 {
  margin-right: 2rem;
}
#footer {
  padding-top: 320px;
}
@media only screen and (max-width: 990px) {
  .innovation-hidden {
    display: none !important;
  }
  #footer {
    padding-top: 150px;
  }
}
@media (min-width: 992px) {
  img.innovation-image {
    height: 100px;
    width: auto;
  }
}
.text-dark {
  color: #191919 !important;
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
}
.align-items-center {
  align-items: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}

.link-class {
  padding: 0 1rem 1rem 0rem !important;
}

.font-weight-bold,
.font-weight-bolder {
  font-weight: 700 !important;
}

@media screen and (min-width: 0px) and (max-width: 750px) {
  #my-footer-content-desktop {
    display: none;
  }
  #my-footer-content-mobile {
    display: inline-block;
  }
}
@media screen and (min-width: 751px) {
  #my-footer-content-mobile {
    display: none;
  }
  #my-footer-content-desktop {
    display: inherit;
  }
}
</style>
