<template>
  <div class="faq-section container mt-5 mb-5">
    <div class="container d-flex justify-content-center heading py-5">
      <div class="vl"></div>
    </div>
    <h3 class="faq-heading text-center mb-5">FREQUENTLY ASKED QUESTIONS</h3>

    <div class="row justify-content-center">
      <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
        <div id="accordion" class="accordion">
          <ul class="pl-0 list-group-programs list-faq">
            <li class="d-flex" v-for="(faq, i) in faqs.faq" :key="i">
              <div class="card mb-0">
                <div
                  class="mb-2 faq-card"
                  :id="`TheadingTwo-${i}`"
                  style="
                    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    border-top: 1px solid rgba(0, 0, 0, 0.125);
                  "
                >
                  <div
                    class="card-header collapsed d-flex justify-content-between pr-2 pr-md-0"
                    data-toggle="collapse"
                    :aria-controls="`TcollapseTwo-${i}`"
                    :href="`#Tcollapsetwo0-${i}`"
                  >
                    <a class="card-title font-weight-bold font16">{{
                      faq.question
                    }}</a>
                    <!-- <i class="fa fa-chevron-down"></i> -->
                    <!-- <i class="fas fa-2x fa-angle-up"></i> -->
                  </div>
                  <template v-if="i == 0">
                    <div
                      :aria-labelledby="`TheadingTwo-${i}`"
                      :id="`Tcollapsetwo0-${i}`"
                      class="collapse show"
                      data-parent="#accordion"
                    >
                      <p class="font14 text-left px-3 pt-3" v-html="faq.answer">
                        >
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      :aria-labelledby="`TheadingTwo-${i}`"
                      :id="`Tcollapsetwo0-${i}`"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <p class="font14 text-left px-3 pt-3" v-html="faq.answer">
                        >
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faqs from "../data/faq.json";
export default {
  name: "faq",
  data() {
    return {
      faqs,
    };
  },
};
</script>
<style scoped>
.vl {
  border-left: 3px solid gray;
  height: 62px;
}
</style>
<style lang="scss">
#accordion {
  .btn-primary {
    border-radius: 18px;
    padding: 4px 18px;
    border: none;
    font-weight: 700;
    text-transform: capitalize;
    background: #00306d;
    color: white;
    font-size: 16px;
  }
}
</style>
