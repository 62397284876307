<template>
  <div class="home">
    <headerAir />
    <heroAir />
    <headingAndVideo />
    <headingAndText />
    <exploreAir />
    <faqAir />
    <apply-now />
    <footerAir />
  </div>
</template>

<script>
// @ is an alias to /src
import headerAir from "@/components/headerAir.vue";
import heroAir from "@/components/heroAir.vue";
import headingAndVideo from "@/components/headingAndVideo.vue";
import headingAndText from "@/components/headingAndText.vue";
import exploreAir from "@/components/exploreAir.vue";
import faqAir from "@/components/faqAir.vue";
import footerAir from "@/components/footerAir.vue";
import applyNow from "@/components/applyNow.vue";

export default {
  name: "home",
  components: {
    /* eslint-disable vue/no-unused-components */
    headerAir,
    heroAir,
    headingAndVideo,
    headingAndText,
    exploreAir,
    faqAir,
    footerAir,
    applyNow,
  },
};
</script>
