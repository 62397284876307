<template>
  <div id="footer_primary" class="px-xl-96 container-fluid text-left">
    <div class="container">
      <h3 class="font-36 text-white font-weight-bolder mb-4">Contact us</h3>
      <div class="text-left row">
        <div class="col-md-6 col-12">
          <p class="text-white mb-4 font-weight-bold text-24">
            For prospective Global College students
          </p>
          <div class="website-icon d-inline-flex align-items-center mb-36">
            <img
              src="../assets/images/laptop-solid.svg"
              alt="laptop solid icon"
            />
            <p class="text-white mb-0 ml-3 wordbreak">
              Website:
              <a
                href="https://www.airuniversity.af.edu/GCPME/Help/"
                class="link-color"
                target="_blank"
                >https://www.airuniversity.af.edu/GCPME/Help/</a
              >
            </p>
          </div>
        </div>
        <!-- <div class="base-underline mb-36"></div> -->
        <div class="col-md-6 col-12">
          <p class="text-white font-weight-bold mb-4 text-24">
            For current Global College students
          </p>
          <div class="contact-info d-flex flex-column mb-3">
            <div class="email d-inline-flex pb-3">
              <img
                src="../assets/images/envelope-solid.svg"
                alt="envelope solid icon"
              />
              <p class="text-white mb-0 ml-3">
                Email:
                <a href="mailto:AU.support@asu.edu" class="link-color"
                  >AU.support@asu.edu</a
                >
              </p>
            </div>
            <div class="telephone d-inline-flex">
              <img
                src="../assets/images/phone-solid.svg"
                alt="envelope solid icon"
              />
              <p class="text-white mb-0 ml-3">
                Phone:
                <a href="tel:4809776697" class="link-color">480-977-6697</a>
                or
                <a href="tel:8338652499" class="link-color">833-865-2499</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped >
.px-xl-96 {
  padding-top: 96px;
  padding-bottom: 96px;
  background: #0f152e no-repeat padding-box;
}
.font-36 {
  font-size: 36px;
  line-height: 43px;
}
a:focus {
  color: #ffc627;
  text-decoration: underline;
}
.link-color {
  color: #ffc627;
  text-decoration: underline;
}
.link-color:hover {
  color: #ffc627;
}
.mb-36 {
  margin-bottom: 36px;
}
.base-underline {
  width: 65px;
  border-bottom: 8px solid #ffc627;
}
.text-24 {
  font-size: 24px;
}
.wordbreak {
  word-break: break-word;
}
</style>