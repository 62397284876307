<template>
  <div>
    <div class="container d-flex justify-content-center heading">
      <div class="vl"></div>
    </div>
    <div
      class="container mt-5 mb-5 d-flex justify-content-center col-md-6 col-4"
    >
      <h2 class="heading-head text-uppercase font-weight-bold mb-0">
        AIR UNIVERSITY AND ASU PARTNERSHIP
      </h2>
    </div>
    <div class="container">
      <img
        src="../assets/images/Au_ASU_parternship.jpg"
        class="img-belowvideo"
        alt="A propeller aircraft and an Air Force airman stand backlit against an orange sky."
      />
    </div>
    <div class="container mt-5 mb-5">
      <div class="row d-flex justify-content-center">
        <div
          class="col-md-12 col-lg-9 col-sm-12 d-flex justify-content-row-end text-center px-4"
        >
          <p class="txt-box2 mx-2 mb-5 text-center">
            Air University and Arizona State University are proud to partner to
            bring an agile, adaptive learning environment to the Department of
            the Air Force’s online professional military education. With a
            proven track record of delivering complex online education, ASU
            provides the Air and Space Forces a world-class online learning
            environment to deliver its professional development programs.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headingAndText",
  props: {
    // msg: String
  },
  data() {
    return {
      partner: [
        {
          num: "40%",
          des: "Faculty have Ph.D",
        },
        {
          num: "120+",
          des: "Command Experience",
        },
        {
          num: "12",
          des: "Former Wing/CC’s",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.heading {
  margin-top: 96px;
}

.vl {
  border-left: 3px solid gray;
  height: 62px;
}

.heading-text {
  margin-top: 20px;
}
.heading-head {
  // height:49px;
}
.text-details {
  // padding-top: 32px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.font32 {
  font-size: 32px;
}
</style>
