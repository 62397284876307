var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid p-0 m-0"},[_c('div',{staticClass:"hero-image"},[_c('div',{staticClass:"carousel slide d-flex align-items-center justify-content-center h-100",attrs:{"id":"carouselExampleCaptions","data-ride":"carousel"}},[_vm._m(0),_c('div',{staticClass:"carousel-inner"},[_c('div',{staticClass:"carousel-item active",style:({
            backgroundImage:
              'linear-gradient(180deg,transparent 0,transparent 50%,#000),url(' +
              require('@/assets/images/carousel_1.jpg') +
              ')'
          }),attrs:{"role":"img","aria-label":""}},[_c('img',{staticClass:"d-block w-100",attrs:{"src":"","alt":""}}),_vm._m(1)]),_c('div',{staticClass:"carousel-item",style:({
            backgroundImage:
              'linear-gradient(180deg,transparent 0,transparent 50%,#000),url(' +
              require('@/assets/images/carousel_2.jpg') +
              ')'
          }),attrs:{"role":"img","aria-label":"An airman in the U.S. Air Force looks out the gate of an aircraft over the horizon."}},[_vm._m(2)]),_c('div',{staticClass:"carousel-item",style:({
            backgroundImage:
              'linear-gradient(180deg,transparent 0,transparent 50%,#000),url(' +
              require('@/assets/images/carousel_3.jpg') +
              ')'
          }),attrs:{"role":"img","aria-label":"U.S. rockets launch against the backdrop of a blue sky."}},[_c('img',{staticClass:"d-block w-100",attrs:{"src":"","alt":""}}),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"carousel-indicators"},[_c('li',{staticClass:"active",attrs:{"data-target":"#carouselExampleCaptions","data-slide-to":"0"}}),_c('li',{attrs:{"data-target":"#carouselExampleCaptions","data-slide-to":"1"}}),_c('li',{attrs:{"data-target":"#carouselExampleCaptions","data-slide-to":"2"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-caption d-md-block",staticStyle:{"margin-top":"15rem !important"}},[_c('h1',{staticClass:"hero-text text-light text-uppercase font-weight-bold"},[_vm._v("YOUR NEXT LEVEL")]),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('a',{staticClass:"hero-button text-light text-light",attrs:{"href":"#explore-section","id":"sliderhero1"}},[_vm._v("Explore")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-caption d-md-block",staticStyle:{"margin-top":"15rem !important"}},[_c('h1',{staticClass:"hero-text text-light text-uppercase font-weight-bold"},[_vm._v("YOUR NEXT LEVEL")]),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('a',{staticClass:"hero-button text-light text-light",attrs:{"href":"#explore-section","id":"sliderhero2"}},[_vm._v("Explore")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-caption d-md-block",staticStyle:{"margin-top":"15rem !important"}},[_c('h1',{staticClass:"hero-text text-light text-uppercase font-weight-bold"},[_vm._v("YOUR NEXT LEVEL")]),_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('a',{staticClass:"hero-button text-light text-light",attrs:{"href":"#explore-section","id":"sliderhero3"}},[_vm._v("Explore")])])])
}]

export { render, staticRenderFns }