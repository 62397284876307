<template>
  <div class="container-fluid p-0 m-0">
    <div class="hero-image">
      <div
        id="carouselExampleCaptions"
        class="carousel slide d-flex align-items-center justify-content-center h-100"
        data-ride="carousel"
      >
        <ol class="carousel-indicators">
          <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
          <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div
            class="carousel-item active"
            :style="{
              backgroundImage:
                'linear-gradient(180deg,transparent 0,transparent 50%,#000),url(' +
                require('@/assets/images/carousel_1.jpg') +
                ')'
            }"
            role="img"
            aria-label
          >
            <img src class="d-block w-100" alt />
            <div class="carousel-caption d-md-block" style="margin-top: 15rem !important;">
              <h1 class="hero-text text-light text-uppercase font-weight-bold">YOUR NEXT LEVEL</h1>
              <div class="d-flex justify-content-center mt-5">
                <a
                  href="#explore-section"
                  id="sliderhero1"
                  class="hero-button text-light text-light"
                >Explore</a>
              </div>
            </div>
          </div>
          <div
            class="carousel-item"
            :style="{
              backgroundImage:
                'linear-gradient(180deg,transparent 0,transparent 50%,#000),url(' +
                require('@/assets/images/carousel_2.jpg') +
                ')'
            }"
            role="img"
            aria-label="An airman in the U.S. Air Force looks out the gate of an aircraft over the horizon."
          >
            <!-- <img src class="d-block w-100" alt /> -->
            <div class="carousel-caption d-md-block" style="margin-top: 15rem !important;">
              <h1 class="hero-text text-light text-uppercase font-weight-bold">YOUR NEXT LEVEL</h1>
              <div class="d-flex justify-content-center mt-5">
                <a
                  href="#explore-section"
                  id="sliderhero2"
                  class="hero-button text-light text-light"
                >Explore</a>
              </div>
            </div>
          </div>
          <div
            class="carousel-item"
            :style="{
              backgroundImage:
                'linear-gradient(180deg,transparent 0,transparent 50%,#000),url(' +
                require('@/assets/images/carousel_3.jpg') +
                ')'
            }"
            role="img"
            aria-label="U.S. rockets launch against the backdrop of a blue sky."
          >
            <img src class="d-block w-100" alt />
            <div class="carousel-caption d-md-block" style="margin-top: 15rem !important;">
              <h1 class="hero-text text-light text-uppercase font-weight-bold">YOUR NEXT LEVEL</h1>
              <div class="d-flex justify-content-center mt-5">
                <a
                  href="#explore-section"
                  id="sliderhero3"
                  class="hero-button text-light text-light"
                >Explore</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "heroAir",
  props: {
    // msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  top: 0px;
  left: 0px;
  width: 1280px;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.text-light:hover {
  color: white !important;
  text-decoration: none !important;
}

#sliderhero1:hover,
#sliderhero3:hover,
#sliderhero2:hover {
  color: #ffffff !important;
  text-decoration: none !important;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
a:hover {
  border: 1px solid white;
  color: #191919;
}

.carousel-indicators li {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.carousel-caption {
  // position: relative !important;
  // left: 0;
  width: 70%;
  bottom: 150px;
  // position: absolute;
  // left: 10%;
  // right: 10%;
  // z-index: 99;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .carousel-item {
    min-height: 430px !important;
    top: 5px !important;
  }
  .carousel-caption {
    left: 10%;
    right: 10%;
    width: 80%;
    bottom: 100px;
  }
}
@media screen and (max-width: 767px) {
  .carousel-indicators {
    margin-bottom: 0px;
  }
}

.carousel-item {
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  top: 40px !important;
  height: 60vh;
  // background-image: ;
}
</style>
